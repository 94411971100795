import Trix from 'trix'

// New actions
// Trix.config.textAttributes.red = {
//   style: { color: 'red' },
//   parser: function(element) {
//     return element.style.color === 'red'
//   },
//   inheritable: true
// }

Trix.config.textAttributes.textCenter = {
  tagName: 'section',
  inheritable: true
}

// Trix.config.textAttributes.textRight = {
//   tagName: 'text-right',
//   inheritable: true
// }

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}

Trix.config.textAttributes.underline = {
  tagName: 'u'
}

// New buttons
addEventListener('trix-initialize', function(event) {
  const buttonHTMLAll = {
    buttonHTMLUnderline: '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline"><i class="fas fa-underline"></i></button>',
    buttonHTMLTextCenter: '<button type="button" class="trix-button trix-button--icon trix-button--icon-text-center" data-trix-attribute="textCenter"><i class="fas fa-align-center"></i></button>',
    // buttonHTMLTextRight: '<button type="button" class="trix-button trix-button--icon trix-button--icon-text-right" data-trix-attribute="textRight"><i class="fas fa-align-right"></i></button>',
    buttonHTMLHeading3: '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-3" data-trix-attribute="heading3"><i class="fas fa-heading"></i> 3</button>',
    buttonHTMLHeading2: '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-2" data-trix-attribute="heading2"><i class="fas fa-heading"></i> 2</button>'
  }

  event.target.toolbarElement.
  querySelector('.trix-button-group').
  insertAdjacentHTML('beforeend', Object.values(buttonHTMLAll).join(''))
})
